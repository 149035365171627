<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 bg-card-ks py-5">
      <h3 class="card-title text-white font-h3-ks">
        Thống kê
      </h3>
      <!--      <div class="card-toolbar">-->
      <!--        <Dropdown1></Dropdown1>-->
      <!--      </div>-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <apexchart
          class="card-rounded-bottom bg-card-ks"
          :options="chartOptions"
          :series="series"
          type="area"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer ">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-empty-room px-6 py-8 rounded-xl mr-7 mb-7">
            <span class="text-information d-block my-2">
             {{ listStatic.number_of_empty_room }}
            </span>
            <a href="#" class=" mt-2">
              Phòng trống
            </a>
          </div>
          <div class="col bg-live-in px-6 py-8 rounded-xl mb-7">
            <span class="text-information d-block my-2">
             {{ listStatic.number_of_lived }}

            </span>
            <a href="#" class=" mt-2">
              Khách đang ở
            </a>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-will-come px-6 py-8 rounded-xl mr-7">
            <span class="text-information d-block my-2 ">
             {{ listStatic.number_of_going }}
            </span>
            <a href="#" class=" mt-2">
              Khách sẽ đến
            </a>
          </div>
          <div class="col bg-out-date px-6 py-8 rounded-xl">
            <span class="text-information d-block my-2 ">
              {{ listStatic.number_of_expired }}
            </span>
            <a href="#" class=" mt-2">
              Khách quá hạn
            </a>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script>
import Dropdown1 from "@/view/content/dropdown/Dropdown1.vue";
import {mapGetters} from "vuex";
import BaseService from "@/core/helper/base.service";

export default {
  name: "widget-1",
  components: {
    Dropdown1
  },
  data() {
    return {
      strokeColor: "#D13647",
      chartOptions: {},
      series: [
        {
          name: "Số lượng",
          data: []
        },
      ],
      listStatic: {}
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  created() {
    BaseService.getDashboardStats().then((res) => {
      console.log('res', res.data.data)
      this.listStatic = res.data.data
      this.series[0].data[0] = this.listStatic.number_of_empty_room
      this.series[0].data[1] = this.listStatic.number_of_lived
      this.series[0].data[2] = this.listStatic.number_of_going
      this.series[0].data[3] = this.listStatic.number_of_expired
      this.series = [...this.series]
      console.log('this.series', this.series)
    })
  },
  mounted() {
    // reference; kt_mixed_widget_1_chart
    this.chartOptions = {
      chart: {
        type: "area",
        height: 200,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: this.strokeColor,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.strokeColor]
      },
      xaxis: {
        categories: ["Phòng trống", "Khách đang ở", "Khách sẽ đến", "Khách quá hạn"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function (val) {
            return val ;
          }
        },
        marker: {
          show: false
        }
      },
      colors: ["transparent"],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.danger")],
        strokeColor: [this.strokeColor],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.row {
  .col {
    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }
}

.font-h3-ks {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.text-information {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.bg-card-ks {
  background: #EE801E !important;
}

.bg-empty-room {
  background: #D1F0D0;

  span {
    color: #30D42C;
  }

  a {
    color: #30D42C;
  }
}

.bg-live-in {
  background: #E6D9E8;

  span {
    color: #B800C8;
  }

  a {
    color: #B800C8;
  }
}

.bg-will-come {
  background: #F0EDCD;

  span {
    color: #C5B208;
  }

  a {
    color: #C5B208;
  }
}

.bg-out-date {
  background: #F8D4D4;

  span {
    color: #FF0000;
  }

  a {
    color: #FF0000;
  }
}
</style>
<style lang="scss">
.row {
  .col {
    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }
}

.font-h3-ks {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.text-information {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.bg-card-ks {
  background: #EE801E !important;
}

.bg-empty-room {
  background: #D1F0D0;

  span {
    color: #30D42C;
  }

  a {
    color: #30D42C;
  }
}

.bg-live-in {
  background: #E6D9E8;

  span {
    color: #B800C8;
  }

  a {
    color: #B800C8;
  }
}

.bg-will-come {
  background: #F0EDCD;

  span {
    color: #C5B208;
  }

  a {
    color: #C5B208;
  }
}

.bg-out-date {
  background: #F8D4D4;

  span {
    color: #FF0000;
  }

  a {
    color: #FF0000;
  }
}
</style>

