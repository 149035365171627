<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-5 col-lg-5 col-md-5">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-7 col-lg-7 col-md-7 ">
        <AdvancedTableWidget3
            :title="'Khách sẽ đến'"
            :text-class="'header-text-will-come'"
            :list="listWillCome"
        />
      </div>


      <div class="col-xxl-5 col-lg-5 col-md-5">
        <AdvancedTableWidget4
            :title="'Khách quá hạn'"
            :text-class="'header-text-too-date'"
            :list="listOutDate"
        />
      </div>
      <div class="col-xxl-7 col-lg-7 col-md-7 order-1 order-xxl-2">
        <AdvancedTableWidget3
            :title="'Khách đang ở'"
            :text-class="'header-text-live-in'"
            :checkout="true"
            :list="listLiveIn"
        />
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../core/services/store/breadcrumbs.module";
import AdvancedTableWidget3 from "../../view/content/widgets/advance-table/Widget3.vue";
import AdvancedTableWidget4 from "../../view/content/widgets/advance-table/Widget4.vue";
import MixedWidget1 from "../../view/content/widgets/mixed/Widget1.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "dashboard",
  components: {
    AdvancedTableWidget3,
    AdvancedTableWidget4,
    MixedWidget1,
  },
  data() {
    return {
      listWillCome: [],
      listOutDate: [],
      listLiveIn: [],
    }
  },
  created() {
    this.getApiWillCome();
    this.getApiLiveIn();
    this.getApiOutDate();
    this.changeDate('13:30 12/01/2020')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },


  //   call api khach se den
    getApiWillCome() {
      // this.isBusy = true;
      // search2: {
      //   name: '',
      //       startTime: '',
      //       endTime: '',
      //       status: null,
      //       sourcesSet: null,
      //       source: null
      // },
      let query = {
        pageSize: 8,
        status: 2,
      }
      // if (this.search2.startTime) {
      //   query.beginAt = this.search2.startTime
      // }
      // if (this.search2.endTime) {
      //   query.endedAt = this.search2.endTime
      // }
      console.log('query', query)
      ApiService.query(`customer`, query)
          .then(({data}) => {
            // this.isBusy = false;
            // this.totalPages = data.data.pagination.totalElements;
            // this.pageSize = data.data.pagination.numberOfElements
            console.log(' data.data.items', data.data.items)
            let items = [];
            data.data.items.map((res) => {
              items.push({
                customer: res.name,
                phone: res.phone,
                type_of_customer: res.booking_type,
                booking_code: res.code,
                room: res.room_code,
                day_checkin: res.begin_at,
                agency: res.source_type_name,
              })
            })
            // customer: "Brad Simmons",
            // phone: "0989126613",
            //     type_of_customer: "Khách lẻ",
            //     booking_code: "#A-009213#1",
            //     room: "B120",
            //     day_checkin: "13:30 12/12/2020",
            //     day_checkout: "13:30 12/12/2020",
            //     agency: "Trực tiếp",
            //     days_remaining: "06"
            this.listWillCome = items
            console.log(34434343,this.listLiveIn)
          })
          .catch(({err}) => {
            // this.isBusy = false;
            console.log(err)
          });
    },
    getApiLiveIn() {
      // this.isBusy = true;
      let query = {
        pageSize: 8,
        status: 3,
      }
      // if (this.search2.startTime) {
      //   query.beginAt = this.search2.startTime
      // }
      // if (this.search2.endTime) {
      //   query.endedAt = this.search2.endTime
      // }
      console.log('query', query)
      ApiService.query(`customer`, query)
          .then(({data}) => {
            console.log(' data.data.items', data.data.items)
            let items = [];
            data.data.items.map((res) => {
              items.push({
                customer: res.name,
                phone: res.phone,
                type_of_customer: res.booking_type,
                booking_code: res.code,
                room: res.room_code,
                day_checkin: res.begin_at,
                day_checkout: res.ended_at,
                days_remaining: this.calculatorDate(this.changeDate(res.begin_at),this.changeDate(res.ended_at)),
                agency: res.source_type_name,
              })
            })
            this.listLiveIn = items
            console.log(34434343,this.listLiveIn)
          })
          .catch(({err}) => {
            console.log(err)
          });
    },
    getApiOutDate() {
      // search2: {
      //   name: '',
      //       startTime: '',
      //       endTime: '',
      //       status: null,
      //       sourcesSet: null,
      //       source: null
      // },
      let query = {
        pageSize: 8,
        status: 4,
      }
      // if (this.search2.startTime) {
      //   query.beginAt = this.search2.startTime
      // }
      // if (this.search2.endTime) {
      //   query.endedAt = this.search2.endTime
      // }
      console.log('query', query)
      let newDate = new Date().toLocaleString().split(',')
      console.log(newDate[1].toString())
      ApiService.query(`customer`, query)
          .then(({data}) => {
            console.log(' data.data.items', data.data.items)
            let items = [];
            data.data.items.map((res) => {
              items.push({
                customer: res.name,
                phone: res.phone,
                type_of_customer: res.booking_type,
                booking_code: res.code,
                room: res.room_code,
                day_checkout: res.ended_at,
                too_date: this.calculatorDate(this.changeDate2(newDate[1].toString()),this.changeDate(res.ended_at)),
              })
            })
            // customer: "Brad Simmons",
            //     phone: "0989126613",
            //     booking_code: "#A-009213#1",
            //     room: "B120",
            //     day_checkout: "13:30 12/12/2020",
            //     too_date: "2",
            this.listOutDate = items
            console.log(34434343,this.listOutDate)
          })
          .catch(({err}) => {
            console.log(err)
          });
    },
    changeDate(_value) {
      let d = _value.split(" ");
      let a = d[1].toString().split("/")
      let b = [a[1],a[0],a[2]].join('/')
      return b
    },
    changeDate2(_value) {
      let a = _value.split("/")
      let b = [a[1],a[0],a[2]].join('/')
      return b
    },
    calculatorDate(_value1, _value2) {
      let date1 = new Date(_value1);
      let date2 = new Date(_value2);

// To calculate the time difference of two dates
      let Difference_In_Time = date2.getTime() - date1.getTime();

// To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Math.abs(Difference_In_Days)
    },
  }
};
</script>
