<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header card-header-ks border-0 py-5">
      <div class="card-toolbar">
        <div class="card-category ml-3">
          <p class=" card-category btn-info-category font-weight-bolder font-size-sm mr-3"
             :class="textClass"
          >
            {{title ? title : 'Danh mục'}}
          </p>
        </div>
      </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-vertical-center table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th class="p-0 title-table-ks">STT</th>
                <th style="min-width: 120px" class="title-table-ks">Khách hàng</th>
                <th style="min-width: 120px;text-align: center"  class="title-table-ks">Mã đặt / Phòng</th>
                <th style="min-width: 120px" class="title-table-ks">Checkout</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in list">
                <tr v-bind:key="index">
                  <td class="pl-0 pt-8">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        {{  index < 10 ? `#0${index + 1}` : `#${index +1}` }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 d-block font-size-lg"
                      >{{ item.customer }}</span
                    >
                    <span class="text-muted">{{
                      item.phone
                    }}</span>
                  </td>
                  <td style="text-align: center" class="room-book">
                    <span
                      class="text-dark-75 d-block font-size-lg"
                      >{{ item.booking_code }}</span
                    >
                    <span class="text-muted font-code-ks">{{
                      item.room
                    }}</span>
                  </td>
                  <td>
                    <span class="text-muted  d-block day-checkout">{{
                      item.day_checkout
                    }}</span>
                    <span class="text-muted  d-block too-date">
                      Quá: {{ item.too_date < 10 ?
                        `0${item.too_date}` : item.too_date
                      }} ngày</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
  </div>
</template>

<script>
export default {
  name: "widget-3",
  // props: ["title", "textClass"],
  props: {
    title: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
    checkout: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () =>[
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkout: "13:30 12/12/2020",
              too_date: "2",
            },
          ]
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // hidenPhone(_phone) {
    //   let newphone = _phone.split("")
    // }
  }
};
</script>
<style lang="scss" scoped>
.btn-info-category {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  background: #E5E5E5;
}
.day-checkout {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #EB5757;
}
.room-book {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #219653;
  .span:first-child {
    font-weight: 400;
  }
  .span {
    font-weight: 700;
  }
}

</style>
<style lang="scss" >
.btn-info-category {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  background: #E5E5E5;
}
.day-checkout {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #EB5757;
}
.room-book {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #219653;
  .span:first-child {
    font-weight: 400;
  }
  .span {
    font-weight: 700;
  }
}

</style>

