<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header card-header-ks border-0 py-5">
      <div class="card-toolbar">
        <div class="card-category ml-3">
          <p class=" card-category btn-info-category font-weight-bolder font-size-sm mr-3"
             :class="textClass"
          >
            {{title ? title : 'Danh mục'}}
          </p>
        </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-vertical-center table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th class="p-0">STT</th>
                <th class="title-table-ks">Khách hàng</th>
                <th class="title-table-ks">Loại khách</th>
                <th class="title-table-ks" style="min-width: 120px; text-align: center">Mã đặt / Phòng</th>
                <th class="title-table-ks" >Ngày Checkin</th>
                <th class="title-table-ks" v-if="checkout">Ngày checkout</th>
                <th class="title-table-ks" v-if="checkout" style="text-align: center">Số ngày còn lại</th>
                <th class="title-table-ks" v-if="!checkout ">Đại lý</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in list">
                <tr v-bind:key="index">
                  <td class="pl-0 pt-8">
                      <span class="symbol symbol-50 symbol-light">
                        {{  index < 10 ? `0${index + 1}` : index +1 }}
                      </span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 d-block font-size-lg"
                      >{{ item.customer }}</span
                    >
                    <span class="text-muted ">{{
                      item.phone
                    }}</span>
                  </td>
                  <td>
                    <span v-if="item.type_of_customer === 1"
                      class=" d-block font-size-lg" style="color: #219653 !important;"
                      >Khách lẻ</span
                    >
                    <span v-if="item.type_of_customer === 2"
                          class=" d-block font-size-lg" style="color: #EB5757 !important;"
                    >Khách đoàn</span
                    >
                  </td>
                  <td style="text-align: center">
                    <span
                      class="text-dark-75  d-block font-size-lg"
                      >{{ item.booking_code }}</span
                    >
                    <span class=" font-code-ks" style="color: ##000000;" >{{
                      item.room
                    }}</span>
                  </td>
                  <td>
                    <span class=" d-block" style="color:#9B51E0;">{{
                      item.day_checkin
                    }}</span>
                  </td>
                  <td v-if="checkout" >
                    <span class="text-muted  d-block">{{
                        item.day_checkout
                      }}</span>
                  </td>
                  <td v-if="checkout" style="text-align: center">{{item.days_remaining}}</td>
                  <td v-if="!checkout ">
                   {{item.agency}}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
  </div>
</template>

<script>
export default {
  name: "widget-3",
  // props: ["title", "textClass"],
  props: {
    title: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
    checkout: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () =>[
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
            {
              customer: "Brad Simmons",
              phone: "0989126613",
              type_of_customer: "Khách lẻ",
              booking_code: "#A-009213#1",
              room: "B120",
              day_checkin: "13:30 12/12/2020",
              day_checkout: "13:30 12/12/2020",
              agency: "Trực tiếp",
              days_remaining: "06"
            },
          ]
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // hidenPhone(_phone) {
    //   let newphone = _phone.split("")
    // }
  }
};
</script>
<style lang="scss" scoped>
.btn-info-category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  background: #E5E5E5;
}

</style>
<style lang="scss" >
.btn-info-category {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  background: #E5E5E5;
}

</style>

